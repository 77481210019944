import axios from 'axios'

export const callGetCollectionTitle = async (term: string) => {
  const { data: collection } = await axios.get(
    'https://decathlonstore.myvtex.com/_v2/api/decathlon-search/product/collection',
    {
      params: {
        engine: 'linx',
        page: 1,
        resultsPerPage: 20,
        sc: 3,
        sort: 'relevance',
        term,
      },
    }
  )

  return collection
}
