/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import type { ParamsApi } from 'src/components/sections/ProductGalleryV2/ProductGallery'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps, GetServerDataProps } from 'gatsby'
import type {
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables,
} from '@generated/graphql'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import axios from 'axios'
import qs from 'qs'
import Seo from 'src/components/seo/Seo'

export type Props = PageProps<
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables,
  unknown,
  { seo: { title: string; description: string } }
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const { serverData } = props

  const title = serverData?.seo?.title
  const description = serverData?.seo?.description

  const searchParams = useSearchParams(props.location)

  const isB2B = checkEnviromentIsB2B()

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <Seo
        language="pt-br"
        title={title ?? ''}
        description={description ?? ''}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto">
        <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4 mb-6">
          <Breadcrumb
            type="single"
            breadcrumbList={[
              {
                item: `/colecao?q=${searchParams.term}`,
                name: searchParams.term ?? '',
                position: 1,
              },
            ]}
          />
        </div>
      </div>

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      <ProductGalleryV2
        title={`Coleção ${searchParams.term}`}
        searchEngine="linx"
        salesChannel={isB2B ? B2B.salesChannel : B2C.salesChannel}
        type="collection"
        typeValidation="byTermOrCollection"
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchColecaoPage {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

export const getServerData = async ({ query }: GetServerDataProps) => {
  if (!query?.q) {
    return {
      status: 400,
      headers: {},
      props: {},
    }
  }

  try {
    const CACHE_CONTROL = `max-age=300, s-maxage=7200, stale-while-revalidate`

    const params: ParamsApi = {
      sc: B2C.salesChannel,
      engine: 'linx',
      term: query?.q as string,
      page: 1,
      resultsPerPage: 1,
      sort: 'relevance',
    }

    const { data } = await axios.get(
      `https://decathlonstore.myvtex.com/_v2/api/decathlon-search/product/collection?${qs.stringify(
        params
      )}`
    )

    if (!data) {
      return {
        status: 404,
        headers: {},
        props: {},
      }
    }

    const title = `${data?.collection?.name} | Decathlon`
    const description = data?.collection?.description

    return {
      status: 200,
      props: {
        seo: {
          title,
          description,
        },
      },
      headers: {
        'cache-control': CACHE_CONTROL,
      },
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    }
  }
}

export default mark(Page)
